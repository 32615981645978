// import React from "react";
// import data from "./DUMMYDATA.json";
// import { Link, useParams } from "react-router-dom";

// import "./JobDetails.css";

// const JobDetails = () => {
//   const { title } = useParams();
//   const currentJob = data.find((job) => job.title === title);

//   const options = { year: "numeric", month: "short", day: "2-digit" };
//   const currentDate = new Date();
//   const formattedDate = currentDate.toLocaleDateString("en-US", options);

//   return (
//     <div className="container">
//       <div className="job-details">
//         <div className="job-heading">
//           <div>
//             <h1 className="job-title">{currentJob.title}</h1>
//             <p className="heading-location" style={{ marginRight: "2rem" }}>
//               {currentJob.location} |
//               <span style={{ color: "lightblue", paddingLeft: "2rem" }}>
//                 {currentJob.type}
//               </span>
//             </p>
//           </div>
//           {/* <Link className="job-apply-form-btn " to={`/job-form/${title}`}>
//             Apply Now
//           </Link> */}
//         </div>

//         <div className="job-body">
//           <div className="about-role">
//             <div className="about-job-role">
//               <h4 className="job-detail-title">About the role</h4>
//               <p>Posted On : {formattedDate}</p>
//             </div>
//             <p className="job-details-description">
//               you are going to use a passage of Lorem Ipsum, you need to be sure
//               there isn't embarrassing hidden in the middle of text. All the
//               Lorem Ipsum generators on the Internet tend to repeat predefined
//               chunks as necessary, making this the first true generator on the
//               Internet.
//             </p>
//             <ul className="job-item-list">
//               <li>
//                 You are going to use a passage of Lorem Ipsum, you need to be{" "}
//               </li>
//               <li>
//                 Sure there isn't embarrassing hidden in the middle of text. All
//                 the
//               </li>
//               <li>
//                 Lorem Ipsum generators on the Internet tend to repeat predefined
//               </li>
//               <li>Chunks as necessary, making this the first true generator</li>
//             </ul>
//           </div>
//           <div>
//             <div>
//               <h3 className="job-detail-requirement">Requirements</h3>
//               <h5 className="job-detail-requirment-title">
//                 Educational Background :
//               </h5>
//               <ul className="job-item-list">
//                 <li>Bsc in Enchet Feleta</li>
//               </ul>
//               <h5 className="job-detail-requirment-title">
//                 Years of Experience :
//               </h5>
//             </div>
//             <ul className="job-item-list">
//               <li>Minimum of 3 Years</li>
//             </ul>
//             <h5 className="job-detail-requirment-title">
//               Personnel required :
//             </h5>
//             <ul className="job-item-list">
//               <li>
//                 5
//               </li>
//             </ul>
//           </div>
//           <div>
//             <h4 className="job-detail-title">Contact us!</h4>
//             <p className="job-details-description contact">
//               Reach out to discuss the opportunity & send your answers to:
//               <br />
//               <span>Abebe Bekele</span>
//               <br />
//               hr@mail.co +251 90000000
//             </p>
//             <div className="job-form-link">
//               <Link className="job-details-link" to={`/job-form/${title}`}>
//                 Apply Now
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobDetails;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import "./JobDetails.css";

const JobDetails = () => {
  const { id } = useParams();
  const [currentJob, setCurrentJob] = useState(null);
  
const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/vacancy/${id}`
        );
        if (response.data.success) {
          setCurrentJob(response.data.vacancy);
        } else {
          throw new Error("Failed to fetch job details");
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!currentJob) {
    return <div class="loader"></div>; // You can render a loading indicator while fetching data
  }

  const options = { year: "numeric", month: "short", day: "2-digit" };
  const formattedDate = new Date(currentJob.createdAt).toLocaleDateString(
    "en-US",
    options
  );

return (
  <div className="container">
    <div className="job-details">
      <div className="job-heading">
        <div>
          <h1 className="job-title">{currentJob.title}</h1>
          <div className="job-content-details">
            <p className="job-loc">
              {currentJob.experienceLevel.charAt(0).toUpperCase() +
                currentJob.experienceLevel.slice(1)}
            </p>
            <span className="job-type">
              {currentJob.jobType.charAt(0).toUpperCase() +
                currentJob.jobType.slice(1)}
            </span>
          </div>
        </div>
        {/* <Link className="job-apply-form-btn " to={`/job-form/${title}`}>
            Apply Now
          </Link> */}
      </div>

      <div className="job-body">
        <div className="about-role">
          <div className="about-job-role">
            <h4 className="job-detail-title">About the role</h4>
            <p className="date">Posted On : {formattedDate}</p>
          </div>
          <p className="job-details-description">{currentJob.description}</p>
          <h5 className="job-detail-requirment-title">Salary:</h5>
          <ul className="job-item-list">
            <li>{currentJob.salary}</li>
          </ul>
        </div>
        <div>
          <div>
            <h3 className="job-detail-requirement">Requirements</h3>
            <h5 className="job-detail-requirment-title">
              Educational Background :
            </h5>
            <ul className="job-item-list">
              <li>{currentJob.educationalBackground}</li>
            </ul>
            <h5 className="job-detail-requirment-title">
              Educational Status:
            </h5>
            <ul className="job-item-list">
              <li>{currentJob.educationalQualification}</li>
            </ul>
            <h5 className="job-detail-requirment-title">
              GPA :
            </h5>
            <ul className="job-item-list">
              <li>{currentJob.gpa} and above</li>
            </ul>
            <h5 className="job-detail-requirment-title">Gender :</h5>
            <ul className="job-item-list">
              <li>
                {currentJob.gender.charAt(0).toUpperCase() +
                  currentJob.gender.slice(1)}
              </li>
            </ul>
            <h5 className="job-detail-requirment-title">Experience Level:</h5>
            <ul className="job-item-list">
              <li>
                {currentJob.experienceLevel.charAt(0).toUpperCase() +
                  currentJob.experienceLevel.slice(1)}
              </li>
            </ul>
            <h5 className="job-detail-requirment-title">
              Years of Experience :
            </h5>
            <ul className="job-item-list">
              <li>{currentJob.yearsOfExperience}</li>
            </ul>
          </div>

          <h5 className="job-detail-requirment-title">Personnel required :</h5>
          <ul className="job-item-list">
            <li>{currentJob.numberOfVacancy}</li>
          </ul>
        </div>
        <div>
          <h4 className="job-detail-title">Contact us!</h4>
          <p className="job-details-description-contact">
            Yasmin Jemal Construction PLC
            <br />
            <span>Amanela Building 2nd floor</span>
            <br />
            0114-70-12-87 / 0925564802
          </p>
          <div className="job-form-link">
            <Link
              className="job-details-link"
              to={`/job-form/${currentJob._id}`}
            >
              Apply Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default JobDetails;
