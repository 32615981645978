import React, { useEffect } from "react";
import "./SuccessPopup.css";
import { FaRegCopy } from "react-icons/fa6";

const SuccessPopup = ({ onClose, trackingNumber }) => {
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(trackingNumber)
      .then(() => {
        console.log("Tracking number copied to clipboard:", trackingNumber);
        // You can optionally show a success message or perform any other action
      })
      .catch((error) => {
        console.error("Failed to copy tracking number:", error);
        // Handle any error that occurred while copying
      });
  };
;

  return (
    <div className="success-popup">
      <div className="success-popup-content">
        <h2>Job Applied Successfully!</h2>
        <h5>Your Tracking Number is :</h5>
        <h6>{trackingNumber}</h6>
        <button className="copy-btn" onClick={copyToClipboard}>
          <FaRegCopy /> Copy Tracking Number
        </button>
        <button className="close-btn" onClick={onClose}>
          X
        </button>
      </div>
    </div>
  );
};

export default SuccessPopup;
