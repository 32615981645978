import React from "react";
import "./singlefileUpload.css";
const SingleFileUploader = ({ name, onChange }) => {
  const handleFileChange = (e) => {
    onChange(e); // Pass the event to the parent component
  };

  return (
    <div className="file-uploader">
   
      <input
        type="file"
        id={name}
        name={name}
        onChange={handleFileChange}
        accept=".pdf,.doc,.docx" // Specify accepted file types if needed
        className="file-uploader-input"
      />
    </div>
  );
};

export default SingleFileUploader;
