import React, { useState } from "react";
import "./JobForm.css";
import SingleFileUploader from "./singlefileuploader";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import SuccessPopup from "./SuccessPopup";

const JobForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    countryCode: "",
    email: "",
    phone: "",
    yearsOfExperience: "",
    university: "",
    gpa: "",
    educationalQualification: "",
    address: "",
    file: null, // Added file state
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const handleChange = (e) => {
    if (e.target.name === "file") {
      // For file input
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0], // Store file object
      }));
    } else {
      // For other inputs
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [e.target.name]: "", // Clear the error for this field
      }));

      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.firstName.trim()) {
      errors.firstName = "First Name is required";
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      errors.lastName = "Last Name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^\d{9}$/.test(formData.phone)) {
      errors.phone = "Phone number must be 9 digits";
      isValid = false;
    }

    if (!formData.yearsOfExperience.trim()) {
      errors.yearsOfExperience = "Years Of Experience is required";
      isValid = false;
    }
    if (!formData.educationalQualification.trim()) {
      errors.educationalQualification = "Educational Status is required";
      isValid = false;
    }
    if (!formData.gpa.trim()) {
      errors.gpa = "GPA is required";
      isValid = false;
    }
    //  // Add validation for other inputs

    if (!formData.address.trim()) {
      errors.address = "Please select an address";
      isValid = false;
    }
    if (!formData.university.trim()) {
      errors.university = "University is required";
      isValid = false;
    }

    if (!formData.file) {
      errors.file = "Resume file is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("cv", formData.file);
      formDataToSend.append("yearsOfExperience", formData.yearsOfExperience);
      formDataToSend.append(
        "fullName",
        `${formData.firstName} ${formData.lastName}`
      );
      formDataToSend.append("address", formData.address);
      formDataToSend.append("gpa", formData.gpa);
      formDataToSend.append("university", formData.university);
      formDataToSend.append(
        "educationalQualification",
        formData.educationalQualification
      );
      formDataToSend.append("vacancy", id); // Use route parameter
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone", `+251${formData.phone}`);

      const response = await axios.post(
        `${baseURL}/application/`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        const trackingNumber = response.data.trackingNumber; // Extract tracking number from response
        console.log("Tracking Number:", trackingNumber);
        setTrackingNumber(trackingNumber);
        // Further handling of tracking number, such as displaying in UI or storing in state
      } else {
        throw new Error(response.data.message || "Failed to submit form"); // Throw error if submission unsuccessful
      }
      setSubmitSuccess(true);

      setFormData({
        firstName: "",
        lastName: "",
        countryCode: "",
        email: "",
        phone: "",
        yearsOfExperience: "",
        university: "",
        gpa: "",
        educationalQualification: "",
        address: "",
        file: null,
      }); // Clear form data
      setTimeout(() => {
        setRedirectToHome(true);
      }, 3000); // Handle response as needed
    } catch (error) {
      setSubmitError(error.response.data.message);

      console.error("Error submitting form:", error);
    } finally {
      setLoading(false); // Set loading to false after submission completes
    }
  };

  const closePopup = () => {
    setSubmitSuccess(false);
  };

  return (
    <div className="container">
      <h1 className="form-heading">Job Post Application</h1>
      <div className="form-content">
        {submitSuccess && (
          <SuccessPopup trackingNumber={trackingNumber} onClose={closePopup} />
        )}
        <form>
          <div className="form-group">
            <label>FirstName *</label>
            <input
              className="form-control"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              disabled={loading}
            />
            {formErrors.firstName && (
              <span className="error">{formErrors.firstName}</span>
            )}
          </div>
          <div className="form-group">
            <label>LastName *</label>
            <input
              className="form-control"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              type="text"
              disabled={loading}
            />
            {formErrors.lastName && (
              <span className="error">{formErrors.lastName}</span>
            )}
          </div>
          <div className="form-group">
            <label>Mobile No.*</label>
            <div style={{ display: "flex", gap: "0.3em" }}>
              <input
                type="text"
                style={{ width: "60px" }}
                className="form-control"
                placeholder="+251"
                name="countryCode"
                value="+251"
                readOnly={true}
              />
              <input
                className="form-control"
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="90000000"
                disabled={loading}
              />
            </div>
            {formErrors.phone && (
              <span className="error">{formErrors.phone}</span>
            )}
          </div>
          <div className="form-group">
            <label>email*</label>
            <input
              className="form-control"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="email@gmail.com"
              disabled={loading}
            />
            {formErrors.email && (
              <span className="error">{formErrors.email}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="address">Educational Status*</label>
            <select
              className="form-control"
              name="educationalQualification"
              value={formData.educationalQualification}
              onChange={handleChange}
              disabled={loading}
            >
              <option>-- Select Status ---</option>
              <option value="BSC">Bsc</option>
              <option value="MSC">Msc</option>
              <option value="PHD">Phd</option>
              <option value="Any">Other</option>

              {/* Add more options as needed */}
            </select>
            {formErrors.educationalQualification && (
              <span className="error">
                {formErrors.educationalQualification}
              </span>
            )}
          </div>
          <div className="form-group">
            <label>Experience*</label>
            <input
              className="form-control"
              type="number"
              name="yearsOfExperience"
              value={formData.yearsOfExperience}
              onChange={handleChange}
              placeholder="Number Of Years Experience"
              disabled={loading}
            />
            {formErrors.yearsOfExperience && (
              <span className="error">{formErrors.yearsOfExperience}</span>
            )}
          </div>
          <div className="form-group">
            <label>GPA*</label>
            <input
              className="form-control"
              type="number"
              name="gpa"
              value={formData.gpa}
              onChange={handleChange}
              placeholder="GPA"
              disabled={loading}
            />
            {formErrors.gpa && <span className="error">{formErrors.gpa}</span>}
          </div>
          <div className="form-group">
            <label>University *</label>
            <input
              className="form-control"
              type="text"
              name="university"
              value={formData.university}
              onChange={handleChange}
              placeholder="University"
              disabled={loading}
            />
            {formErrors.university && (
              <span className="error">{formErrors.university}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="address">Address*</label>
            <select
              className="form-control"
              name="address"
              value={formData.address}
              onChange={handleChange}
              disabled={loading}
            >
              <option>-- Select Address ---</option>
              <option value="Addis Ababa">Addis Ababa</option>
              <option value="Dire Dawa">Dire Dawa</option>
              <option value="Mekelle">Mekelle</option>
              <option value="Gondar">Gondar</option>
              <option value="Bahir Dar">Bahir Dar</option>
              <option value="Hawassa">Hawassa</option>
              <option value="Jimma">Jimma</option>
              <option value="Dessie">Dessie</option>
              <option value="Adama">Adama</option>
              <option value="Harar">Harar</option>
              {/* Add more options as needed */}
            </select>
            {formErrors.address && (
              <span className="error">{formErrors.address}</span>
            )}
          </div>

          <div className="form-group">
            <label>Upload Resume*</label>
            <SingleFileUploader
              name="file" // Pass name as prop to identify in handleChange
              onChange={handleChange}
              disabled={loading}
            />
            {formErrors.file && (
              <span className="error">{formErrors.file}</span>
            )}
          </div>
          {submitError && <div style={{ color: "red" }}>{submitError}</div>}
        </form>
        <div className="form-btn">
          <button
            className="btn btn-submit"
            onClick={submitHandler}
            disabled={loading}
          >
            {loading ? "Applying ......" : "Apply"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobForm;
