import React from "react";

import "./Career.css";
import Image from "../assets/const1.jpg";
import Image2 from "../assets/const2.jpg";
import Benifits from "./Benifits";
import JobList from "./job/JobList";

const Career = () => {
  return (
    <div className="container">
      <div className="career-wrapper">
        <div className="career-content">
          <div>
            <h1>Careers</h1>
          </div>
        </div>
        <div className="home-img">
          <img src={Image} width={"100%"} alt="imagefor thest" />
          <img src={Image2} width={"100%"} alt="imagefor thest" />
        </div>
      </div>
      <div className="">
        <Benifits />
      </div>
      <JobList />
    </div>
  );
};

export default Career;
