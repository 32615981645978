import React, { useState } from "react";
import "./Header.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";
import { IoMdClose } from "react-icons/io";
const Header = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [showTrackingInput, setShowTrackingInput] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [applicationData, setApplicationData] = useState(null);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const toggleTrackingInput = () => {
    setShowTrackingInput(!showTrackingInput);
  };

  const handleChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseURL}/application/${trackingNumber}`
      );
      const data = response.data.application;
      setApplicationData(data);
      setShowTrackingInput(false);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching application data:", error);
      console.log(error.response.data.message);
      setErrorData(error.response.data.message);
    }
  };
  const formatScheduledTime = (scheduledTime) => {
    const date = new Date(scheduledTime);
    // Convert to Nairobi time (UTC +03:00)
    date.setUTCHours(date.getUTCHours());

    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    return `${formattedDate}`;
  };

  return (
    <div className="header">
      <h2>
        <Link to="/"> YJBC </Link>{" "}
      </h2>
      <div className="navbar">
        <button
          onClick={toggleTrackingInput}
          className="auth-portal"
          disabled={loading}
        >
          Check Status
        </button>
        {showTrackingInput && (
          <div className="popup-container">
            <div className="popup">
              <form onSubmit={handleSubmit}>
                <button className="close-btnx" onClick={toggleTrackingInput}>
                  <IoMdClose />
                </button>
                <label>Enter Tracking Number:</label>
                <input
                  type="text"
                  value={trackingNumber}
                  onChange={handleChange}
                  className="small-input"
                  disabled={loading}
                  // Add a custom class for small input
                />
                <button
                  type="submit"
                  className="small-button"
                  disabled={loading}
                >
                  {loading ? "Loading........." : "Submit"}
                </button>{" "}
                {/* Add a custom class for small button */}
                {error && <p className="error-message">Error: {errorData}</p>}
              </form>
            </div>
          </div>
        )}
      </div>
      {applicationData && (
        <div className="popup-container">
          <div className="popup-answer">
            <button
              className="close-btnxx"
              onClick={() => setApplicationData(null)
              }
            >
              <IoMdClose />
            </button>
            <div>
              <p>Full Name: {applicationData.fullName}</p>
              {applicationData.status === "scheduled" && (
                <div>
                  <p>Status: {applicationData.status}</p>
                  <p>
                    Interview Time:{" "}
                    {formatScheduledTime(applicationData.interviewDate) +
                      " : " +
                      applicationData.interviewTime +
                      " Local Time"}
                  </p>
                </div>
              )}
              {applicationData.status === "rejected" && (
                <div>
                  <p>Status: {applicationData.status}</p>
                  <p>Reason for Rejection: {applicationData.rejectionReason}</p>
                </div>
              )}
              {applicationData.status === "approved" && <p>Status: Approved</p>}
              {applicationData.status === "pending" && <p>Status: Pending</p>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
