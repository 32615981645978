import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ScrollToTopLink from "../ScrollToTopLink";
import "./JobList.css";
import { FaCalendarAlt, FaShare } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";

const JobList = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [vacancies, setVacancies] = useState([]);

  const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/vacancy/all?page=${currentPage}&limit=6`
        );
        const totalData = response.data.totalData;
        setTotalPages(Math.ceil(totalData / 6));
        setTotalData(totalData);
        // Assuming 10 items per page
        const vacancies = response.data.vacancies;
        setVacancies(vacancies);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        throw error;
      }
    };

    fetchData();
  }, [currentPage]);
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };
  const formatDate = (datetime) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(datetime).toLocaleDateString("en-US", options);
  };
  const handleShare = async (job) => {
    try {
      const shareLink = `/${job._id}`;
      if (navigator.share) {
        await navigator.share({
          title: job.title,
          text: job.experienceLevel, // Or any other suitable text
          url: shareLink,
        });
        console.log("Job shared successfully.");
      } else {
        throw new Error("Web Share API not supported.");
      }
    } catch (error) {
      console.error("Error sharing job:", error);
    }
  };
  return (
    <div className="content">
      <div className="benifit-heading">
        <h4>Open Positions</h4>
      </div>
      {loading ? (
        <div class="loader"></div>
      ) : (
        <ul className="job-list">
          {vacancies.map((job, id) => (
            <li key={id} className={`job-list-item`}>
              <Link
                to={`/${job._id}`}
                className="job-card-link"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="job-content">
                  <div className="job-heading-content">
                    <div>
                      <div className="expiry-date">
                        <FaCalendarAlt className="i" />
                        <span className="expiry-date-text">
                          Deadline : {formatDate(job.expiryDate)}
                        </span>
                      </div>
                      <h4 className="job-item-title">{job.title}</h4>

                      <div className="job-content-location">
                        <p className="job-loc">
                          {job.experienceLevel.charAt(0).toUpperCase() +
                            job.experienceLevel.slice(1)}
                        </p>
                        <span className="job-type">
                          {job.jobType.charAt(0).toUpperCase() +
                            job.jobType.slice(1)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="job-description">
                    <p>Education Background : {job.educationalBackground}</p>
                    <p>
                      Gender :{" "}
                      {job.gender.charAt(0).toUpperCase() + job.gender.slice(1)}
                    </p>
                  </div>
                  <div className="apply-link">
                    <ScrollToTopLink to={`/${job._id}`}>
                      {" "}
                      <Link className="job-apply-link">View More</Link>
                    </ScrollToTopLink>
                    <button
                      className="share-button"
                      onClick={() => handleShare(job)}
                    >
                      <FiShare2 className="share-icon" />
                      <span className="share-text">Share</span>
                    </button>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
      {totalData > 0 && (
        <div className="pagination">
          <button onClick={goToFirstPage} disabled={currentPage === 1}>
            {"<<"}{" "}
          </button>
          <button onClick={prevPage} disabled={currentPage === 1}>
            {"<"}
          </button>
          <span>
            {currentPage} / {totalPages}
          </span>
          <button onClick={nextPage} disabled={currentPage === totalPages}>
            {">"}
          </button>
          <button onClick={goToLastPage} disabled={currentPage === totalPages}>
            {">>"}
          </button>
        </div>
      )}
    </div>
  );
};

export default JobList;
